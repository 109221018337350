import { graphql } from "gatsby"
import React from "react"

import Layout from "../../components/layouts"
import Banner from "../../components/resources/blog/Banner"

// Query for the Course content in Prismic
export const query = graphql`
  query PrivacyQuery {
    seo: site {
      siteMetadata {
        url
        twitterUsername
      }
    }
  }
`

const Privacy = props => {
  // Banner content
  const bannerContent = {
    categories: "",
    title: "Privacy Policy",
    //   author: content.author
    //     ? content.author.first_name + " " + content.author.last_name
    //     : "",
    //   date: content.date_created,
  }

  const seo = {
    title: "Workhub Privacy Policy",
    description: null,
    //   image: (imageUrl) ? imageUrl : null,
    // pathname,
    // article
  }

  return (
    <Layout>
      {/* <SEO {...seo} /> */}
      <Banner bannerContent={bannerContent} />
      <section className="blog_area_two sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 blog_single_info">
              <div className="blog_list_item blog_list_item_two">
                <div className="blog_content">
                  <h1>Privacy Policy</h1>

                  <h2>Introduction</h2>
                  <p>
                  Your privacy is important to Workhub Software Corporation and Workhub Software Inc. (collectively may be referred to as: “Workhub”, “we”, “us”, “our”). This Privacy Policy describes how we collect, use, disclose and secure your personal information, as well as any rights you/we may have with respect to your personal information. This Privacy Policy applies whenever you interact with us, including when you visit our websites located at Workhub.com, safetysync.com and workerid.com (collectively the “Website”) and when you use our services offered through our Website, or you are engaged as a contractor with us. 
                  </p>
                  <p>
                  By visiting our Website and using our services, or accepting an engagement as a contractor, you accept all of the terms and conditions of this Privacy Policy and consent to the practices described herein.
                  </p>

                  <h2>TERMS AND CONDITIONS</h2>

                  <h3>1. Definition of "Personal Information"</h3>
                  <p>
                  “Personal information” means any information about an identifiable individual. This may include, for example, your name, contact information, email address, bank information, credit card information, and information relating to your WORKHUB account. It may also include other types of more technical information, but only when this information can identify you as an individual. 
                  </p>
                  <p>
                  Information that is aggregated and/or de-identified and cannot be associated with an identifiable individual is not considered to be personal information.
                  </p>

                  <h3>2. Personal Information Collection</h3>
                  <p>
                  We collect personal information in a variety of ways, including directly from you, from other sources and automatically through online technologies. 
                  </p>

                  <h4>a. Information We Collect Directly from You</h4>
                  <p>
                  We collect information directly from you when you use the Website or otherwise interact with us, including information you provide to us. 
                  </p>
                  <p>For instance:</p>
                  <ul style={{ marginLeft: "40px" }}>
                    <li>
                    When you register on our Website, you generally provide registration information, such as your name, street address, email address, phone number, username, password, and credit card information.  
                    </li>
                    <li>
                    We collect information about your use of our services, including about the transactions you complete.
                    </li>
                    <li>
                      When you contact us with an inquiry, issue, request, or
                      complete a satisfaction survey, we collect the information
                      you provide.
                    </li>
                    <li>
                      When you create an account and license our software or
                      course materials.
                    </li>
                    <li>
                      When you load certificates, policies, training, or other
                      information or acquire our products.
                    </li>
                    <li>
                      When you take surveys, exams, or approve policies or
                      procedures.{" "}
                    </li>
                  </ul>
                  <h4>b. From Other Sources</h4>
                  <p>
                    We may collect personal information from other sources,
                    including your employer or former employer.
                  </p>
                  <p>For instance:</p>
                  <ul style={{ marginLeft: "40px" }}>
                    <li>
                      When you request your data transferred from another
                      company.
                    </li>
                  </ul>
                  <h4>
                    c. Information We Collect Automatically through Online
                    Technologies
                  </h4>
                  <p>
                    We may also collect certain types of information
                    automatically when you interact with our Website, emails,
                    social media accounts and online advertising, through
                    technologies such as cookies, web beacons or single pixel
                    gifs or analytics engines. We treat this information as
                    personal information when it can identify you as an
                    individual.
                  </p>
                  <p>
                    We may collect information automatically online as follows:
                  </p>
                  <ul style={{ marginLeft: "40px" }}>
                    <li>
                      <b>Cookies.</b> To help us tailor our Website, and the
                      tools and applications on our Website, to the needs and
                      interests of our users, we keep track of visitor
                      interactions with our Website through the use of cookies.
                      Cookies are small text files stored on your browser when
                      you visit a website or mobile applications. The “Help”
                      function on most web browsers contains information on how
                      to set your browser to disable cookies. However, if you do
                      not accept cookies, you will not be able to take advantage
                      of some features of our Website. We use cookies to improve
                      the user experience on our Website by making it more
                      responsive to the needs of our users and to make it easier
                      for us to recognize you when you return to our Website. We
                      also use cookies to analyze our Website’s performance and
                      to measure advertising performance.
                    </li>
                    <li>
                      <b>Web Beacons.</b> To help us (and in some cases our
                      advertisers and third-party service providers) keep track
                      of how users are accessing particular pages or features
                      within our Website, and to track how users respond to ads
                      and to other content we display on our Website, we may
                      place a small file, called a web beacon, on some of our
                      pages and on some of the listings and ads we distribute.
                      We may also place a web beacon in the emails we send to
                      you. When you open one of our emails or click on links
                      within these emails, we track this click-through data to
                      help us determine your interest in particular topics and
                      measure the effectiveness of our communications to you.{" "}
                    </li>
                    <li>
                      <b>Web Analytics.</b> We may collect, or have a third
                      party collect on our behalf, data on how visitors use and
                      navigate through our Website, such as the number of users
                      who visit various pages within our Website, what they
                      click on, whether they scroll up or down on particular
                      pages, fill out forms, etc. We use this information to
                      improve our Website and learn about users, and may
                      disclose it to our affiliates, or to third parties.in a
                      summary form where no personal identifiers are included.{" "}
                    </li>
                    <li>
                      <b>Social Media.</b> When you use one of the social media
                      tools available on our Website, we and the social media
                      company operating the tool may collect information about
                      you based on such use. The social media company’s use of
                      that information will be subject to its own privacy
                      policy.
                    </li>
                  </ul>
                  <p>
                    To learn more about the privacy choices available to you,
                    please refer to the Opting Out section.
                  </p>

                  <h3>3. Use of Personal Information</h3>
                  <p>
                    We generally use your personal information to provide the
                    services offered through our Website, and to administer our
                    relationship with you. We may also use personal information
                    to manage our business operations, to improve our Website
                    and our services, to communicate information and marketing
                    material we think might interest you, to enhance your
                    customer experience with us, and as otherwise permitted or
                    required by law.
                  </p>
                  <p>
                    We may use your personal information for the following
                    purposes:
                  </p>
                  <h4>a. Providing the Services offered through our Website</h4>
                  <p>
                    We use your personal information in order to provide the
                    services offered through our Website and software, for
                    instance:
                  </p>
                  <ul style={{ marginLeft: "40px" }}>
                    <li>
                      Completing your account registration, verifying your
                      identity and determining your eligibility to use our
                      services;
                    </li>
                    <li>Taking or verifying instructions from you;</li>
                    <li>Processing your transactions;</li>
                    <li>
                      Creating, administering, and maintaining your account;
                    </li>
                    <li>Fulfilling your requests and inquiries.</li>
                  </ul>
                  <p>
                    An administrator may assign you an account, login
                    information and tasks from your employer. In such cases the
                    administrator will set up your initial account and we will
                    ask for additional information when you login to use the
                    service.
                  </p>

                  <h4>b. Managing Our Business</h4>
                  <p>
                    We use your personal information to manage our business, for
                    instance:
                  </p>
                  <p>
                  <li>Managing and developing our business and operations;</li>
                  <li>Managing and administering software licenses;</li>
                  <li>
                    Notifying you of changes to your account or our Website’
                    services, terms, conditions or policies and to provide you
                    administrative messages, updates, legal notices, technical
                    notices or security alerts;{" "}
                  </li>
                  <li>
                    Deploying and managing our information technology
                    applications and systems, including managing our Website;
                  </li>
                  <li>
                    Managing and facilitating the use of our Website and
                    Software, which may include using cookies and other similar
                    technology (as further detailed in the Personal Information
                    Collection section);
                  </li>
                  <li>
                    Enabling your participation in contests, promotions, surveys
                    or chats;
                  </li>
                  <li>
                    Protecting us and third parties from errors and fraud;
                  </li>
                  <li>
                    Monitoring and investigating incidents and managing claims;
                  </li>
                  <li>
                    Meeting our contractual, legal and regulatory obligations.
                  </li>
                  </p>

                  <h4>c. Marketing and Advertising</h4>
                  <p>
                    We may use your personal information to market our services
                    and our Website.{" "}
                  </p>
                  <p>We may do this in a variety of ways:</p>
                  <ul style={{ marginLeft: "40px" }}>
                    <li>
                      By communicating with you by email, telephone, SMS,
                      through our software or direct mail to inquire about your
                      experience on our Website, to provide you with information
                      about our services. From time to time, we may also send
                      you special promotions and other commercial offers that we
                      think may be of interest to you.{" "}
                    </li>
                    <li>
                      By delivering online advertisements, including through our
                      participation in advertising networks that collect the
                      data from your activities on our Website and other
                      websites and receive data from other sources; and{" "}
                    </li>
                    <li>
                      By customizing the content displayed when you visit our
                      Website.
                    </li>
                  </ul>
                  <p>
                    For information on managing your marketing preferences,
                    including unsubscribing from receiving commercial electronic
                    messages, please refer to the Opting Out section.
                  </p>
                  <h4>d. Conducting Market Research and Data Analytics</h4>
                  <p>
                    We may use your personal information in order to conduct
                    market research and data analytics by tracking and analyzing
                    current or previously collected information for the
                    following purposes:
                  </p>
                  <ul style={{ marginLeft: "40px" }}>
                    <li>
                      Improving or developing new services and our Website to
                      better understand our customer base and behaviour;
                    </li>
                    <li>
                      Understanding customer needs and preferences and
                      customizing how we tailor and market products and services
                      to our customers based on their interests;{" "}
                    </li>
                    <li>Measuring the effectiveness of our marketing; and</li>
                    <li>
                      Understanding how visitors interact with our Website and
                      ensure it works correctly.
                    </li>
                  </ul>
                  <p>
                    When possible, we will use your information in an aggregated
                    and/or de-identified format so that it no longer identify
                    you.
                  </p>

                  <h3>4. Disclosure of Personal Information </h3>
                  <p>
                    We may share your personal information with service
                    providers (companies operating on our behalf) for the
                    purposes described in this Privacy Policy and in accordance
                    with applicable law. We will not share your personal
                    information with any non affiliated third party without your
                    prior consent, other than as provided in this Privacy Policy
                    or as otherwise authorized by applicable law.
                  </p>
                  <h4>a. Within our Corporate Group</h4>
                  <p>
                    WORKHUB affiliates and subsidiaries may share personal
                    information with each other and use it for the purposes
                    described in this Privacy Policy. This allows WORKHUB and
                    each entity within the group to comply with applicable laws,
                    regulations and requirements and ensures that your
                    information is consistent, accurate and up-to-date. In
                    addition, it increases the quality and relevance of the
                    services you receive and improves your interactions with
                    WORKHUB through easier access to your information within the
                    group.{" "}
                  </p>
                  <h4>b. Service Providers</h4>
                  <p>
                    In the course of providing our services, we may share
                    personal information with service providers who perform
                    services on our behalf. These service providers help us
                    operate our business, technology systems and applications,
                    internal procedures, infrastructure and advertising and
                    marketing. They provide services to us, such as fulfilling
                    transactions, processing credit card payments, sending
                    emails and postal mail, call centres, data hosting, contest
                    administration, providing advertisements, and analytics
                    services (e.g. tracking effectiveness of our marketing
                    campaigns and analyzing usage of our Website). We require
                    these service providers to limit their access to and/or use
                    of personal information to what is required to provide their
                    services and require that those third parties adhere to
                    confidentiality as well as security procedures and
                    protections. Some of our service providers may be in the
                    United States. When your personal information is used or
                    stored in a jurisdiction outside of Canada, it may be
                    subject to the law of this foreign jurisdiction, including
                    any law permitting or requiring disclosure of the
                    information to the government, government agencies, courts
                    and law enforcement in that jurisdiction.{" "}
                  </p>
                  <h4>c. Sale or Transfer of Business or Other Transaction</h4>
                  <p>
                    We may disclose personal information to a third party in
                    connection with a sale or transfer of business or assets, an
                    amalgamation, reorganization or financing of parts of our
                    business (including the proceedings of insolvency or
                    bankruptcy). In the event the transaction is completed, your
                    personal information will remain protected by applicable
                    privacy laws. In the event the transaction is not completed,
                    we will require the other party not to use or disclose your
                    personal information in any manner whatsoever and to
                    completely delete such information, in compliance with
                    applicable laws.{" "}
                  </p>
                  <h4>d. Other Permitted Reasons</h4>
                  <p>
                    Applicable laws may permit or require the disclosure of
                    personal information without consent in specific
                    circumstances (e.g., when investigating and preventing
                    suspected or actual illegal activities, including fraud, or
                    to assist government and law enforcement agencies). These
                    circumstances include situations when permitted or required
                    by law or when necessary to protect our group of companies,
                    our employees, our customers, or others. If this happens, we
                    will not share more personal information than is reasonably
                    required to fulfill that particular purpose.
                  </p>

                  <h3>5. Your Consent</h3>
                  <p>
                    By visiting our Website and using our services and/or
                    submitting information to us in connection with using our
                    Website and services, you are providing your consent to the
                    collection, use and disclosure of personal information as
                    set out in this Privacy Policy.{" "}
                  </p>
                  <p>
                    In some cases, your consent may be “implied”, which means
                    that your permission is assumed based on your action or
                    inaction at the point of collection, use or sharing of your
                    personal information. We will generally obtain consent when
                    we want to use personal information for a new purpose or for
                    a purpose other than those stated at the time of collection
                    or in this Privacy Policy.
                  </p>
                  <p>
                    For information on how to manage your privacy preferences,
                    please refer to the Opting Out section.
                  </p>

                  <h3>6. Opting Out</h3>
                  <p>
                    You have the right to withdraw your consent to the
                    collection, use and sharing of your personal information at
                    any time, subject to legal or contractual restrictions and
                    reasonable notice. However, without such consent, we may
                    limit the services we are able to provide.
                  </p>
                  <p>
                    As a paying user of our software you may always opt out from
                    receiving marketing communications from us by contacting us
                    as at support@workhub.com. Even if you have opted out of
                    receiving marketing communications from us, we may still
                    contact you for transactional purposes, in compliance with
                    applicable laws (e.g., for customer service). The free or
                    trial version of our software includes marketing
                    communications. To opt out of these communications you will
                    need to terminate and cancel your free or trial account by
                    sending an email requesting account termination to
                    support@workhub.com.
                  </p>
                  <h3>7. Retention of Personal Information</h3>
                  <p>
                    We will retain your personal information for as long as
                    necessary to fulfill the purposes for which it was
                    collected, except where otherwise required or permitted by
                    law or to permit our ongoing relationship. Once no longer
                    required, your personal information will be securely
                    destroyed or anonymized (so the information no longer
                    identifies you).
                  </p>
                  <h3>8. Access and Correction</h3>
                  <p>
                    You have the right to access and correct the personal
                    information we hold about you. Upon request, WORKHUB will
                    provide you with access to your personal information and
                    respond to your rectification request within a reasonable
                    timeframe. You may request access or correction by logging
                    into your account or contacting us at Support@workhub.com.
                  </p>
                  <h3>9. Security</h3>
                  <p>
                    We employ organizational, physical and technological
                    measures to protect the confidentiality of personal
                    information and to safeguard personal information against
                    loss or theft, as well as unauthorized access, disclosure,
                    copying, use or modification, in light of, among other
                    things, the sensitivity of the information and the purposes
                    for which it is to be used. These safeguards also apply when
                    we dispose of or destroy your personal information.{" "}
                  </p>
                  <h3>
                    10. Transfer of Personal Information outside of Canada{" "}
                  </h3>
                  <p>
                    In certain cases, we may transfer your personal information
                    outside of Canada, including if our service providers need
                    to access, process or store your personal information in the
                    United States. When your personal information is used or
                    stored in a jurisdiction outside of Canada, it may be
                    subject to the law of this foreign jurisdiction, including
                    any law permitting or requiring disclosure of the
                    information to the government, government agencies, courts
                    and law enforcement in that jurisdiction.{" "}
                  </p>
                  <h3>11. Changes to this Privacy Policy </h3>
                  <p>
                    We may make changes to this Privacy Policy from time to
                    time. Any changes we make will become effective when we post
                    a modified version of the policy on our Website. If the
                    changes we make are significant, we will provide a more
                    prominent notice when required by applicable laws. By
                    continuing to use our Website and our services after the
                    modified version of the Privacy Policy has been posted or
                    after you have been informed of such update, you are
                    accepting the changes to the Privacy Policy. If you do not
                    agree to the changes in our Privacy Policy, it is your
                    responsibility to stop participating in our programs, and/or
                    using our services or purchasing our products. It is your
                    obligation to ensure that you read, understand and agree to
                    the latest version of the Privacy Policy. The “Effective
                    Date” at the top of the Privacy Policy indicates when it was
                    last updated.
                  </p>
                  <h3>12. Contact Us</h3>
                  <p>
                    If you have any questions regarding this Privacy Policy,
                    please contact our Privacy Officer via email at
                    legal@Workhub.com.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Privacy
